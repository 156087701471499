import { defineStore } from 'pinia'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { computed, reactive, ref, toRefs } from 'vue'
import { useLoadingStore } from './loading-store'
import { useToastStore } from './toast-store'
import type { Router } from 'vue-router'
import authService from '@/services/auth-service'
import userService from '@/services/user-service'
import type { User } from '@/types/User'
import { useProfileStore } from './profile-store'
import { resetAllStores } from '@/helpers/store-helpers'
import type { Credentials } from '@/types/Credentials'
import { ApiError } from '@/types/ApiError'
import { handleError } from '@/handlers/error-handler'

export const useAuthUserStore = defineStore('auth-store', () => {
  const loading = useLoadingStore()
  const toast = useToastStore()
  const profileStore = useProfileStore()
  let routerInstance: Router | null = null

  const state = reactive({
    isInitialized: false,
    isAuthReady: false,
    authUser: undefined as User | null | undefined,
    isAuthenticated: false,
  })

  const firebaseUser = ref<any | null>(null)

  const profileUsername = computed(() => state.authUser?.profile.name || '')

  const setRouter = (router: Router) => {
    routerInstance = router
  }

  const login = async (email: string, password: string) => {
    loading.toggleLoading('auth-login-loading')
    try {
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      })
      firebaseUser.value = result.user
      await fetchUserData()
      state.isAuthenticated = true
      return true
    } catch (error) {
      return error
    } finally {
      loading.toggleLoading('auth-login-loading')
    }
  }

  const signup = async (credentials: Credentials) => {
    loading.toggleLoading('auth-register-loading')
    try {
      const auth = await FirebaseAuthentication.getCurrentUser()
      if (auth) {
        await FirebaseAuthentication.signOut()
      }
      await authService.signup({
        email: credentials.email,
        password: credentials.password,
        acknowledged_terms_and_conditions: credentials.acknowledged_terms_and_conditions,
        acknowledged_privacy_policy: credentials.acknowledged_privacy_policy,
      })

      toast.displaySuccess('Success! Please check your email to verify your account.')

      // Sign in the user with Firebase
      await FirebaseAuthentication.signInWithEmailAndPassword({
        email: credentials.email,
        password: credentials.password,
      })
      state.isAuthenticated = true
      return true
    } catch (error) {
      if (error instanceof ApiError) {
        return error.message
      }
      handleError(error)
      return error
    } finally {
      loading.toggleLoading('auth-register-loading')
    }
  }

  const verifyPin = async (payload: string) => {
    try {
      await authService.verifyPin(payload)
      toast.displaySuccess('Thank you, email verified!', 2500)
      return true
    } catch (error) {
      handleError(error)
    }
  }

  const resendPin = async () => {
    try {
      await authService.resendPin()
      return true
    } catch (error) {
      handleError(error)
    }
  }

  const logout = async () => {
    try {
      await FirebaseAuthentication.signOut()
      resetUserData()
      resetAllStores()
      if (routerInstance) {
        await routerInstance.replace({ path: '/fitness-activity', force: true })
      }
    } catch (error) {
      handleError(error)
    }
  }

  const resetUserData = () => {
    state.authUser = undefined
    state.isAuthenticated = false
    state.isAuthReady = false
    state.isInitialized = false
    firebaseUser.value = null
  }

  const fetchUserData = async () => {
    try {
      loading.toggleLoading('fetch-user-data')
      const response = await userService.me()
      state.authUser = response.data
      state.isAuthenticated = true
      profileStore.profile = response.data.profile
    } catch (error) {
      console.log('Error fetching user data', error)
      handleError(error)
      state.isAuthenticated = false
    } finally {
      loading.toggleLoading('fetch-user-data')
    }
  }

  const initAuth = async () => {
    if (state.isInitialized) return
    return new Promise<void>(async (resolve) => {
      state.isInitialized = true // Mark initialized
      loading.toggleLoading('fecth-user-data')

      await FirebaseAuthentication.addListener('authStateChange', async (event) => {
        if (event.user?.uid) {
          firebaseUser.value = event.user
          await fetchUserData()
        } else {
          resetUserData()
        }

        state.isAuthReady = true
        resolve()
      })
    })
  }

  return {
    ...toRefs(state),
    firebaseUser,
    verifyPin,
    resendPin,
    profileUsername,
    setRouter,
    login,
    signup,
    logout,
    initAuth,
    fetchUserData,
  }
})
