<template>
  <ion-app>
    <ion-router-outlet />
    <ModalsContainer class="w-screen h-screen" />
    <BaseLoading :active="loading" />
    <BaseToast />
  </ion-app>
</template>

<script setup lang="ts">
  import { ModalsContainer } from 'vue-final-modal'
  import BaseToast from './components/base/toast/BaseToast.vue'
  import { IonApp, IonRouterOutlet } from '@ionic/vue'
  import { computed } from 'vue'
  import { useLoadingStore } from './stores/loading-store'
  import BaseLoading from '@/components/base/loading/BaseLoading.vue'
  const loadingStore = useLoadingStore()
  const loading = computed(() => loadingStore.isLoading(['fetch-user-data']))
</script>
