// capacitor-http-client.ts
import { useAuthUserStore } from '@/stores/auth-store'
import { CapacitorHttp, type HttpParams, type HttpResponse, type HttpOptions } from '@capacitor/core'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { ApiError } from '../types/ApiError'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string

// Retrieve the current authentication token
const getStoredToken = async (): Promise<string | null> => {
  try {
    const result = await FirebaseAuthentication.getIdToken()
    return result.token || null
  } catch (error) {
    console.warn('Error retrieving token:', error)
    return null
  }
}

// Create headers for API requests
const createHeaders = async (contentType: string = 'application/json'): Promise<Record<string, string>> => {
  const headers: Record<string, string> = { 'Content-Type': contentType }
  const token = await getStoredToken()
  if (token) headers['Authorization'] = `Bearer ${token}`
  return headers
}

// Handle API response, including error and authentication status
const handleResponse = async (response: HttpResponse): Promise<HttpResponse> => {
  const { status, data } = response

  if (status === 401 || status === 403) {
    useAuthUserStore().isAuthenticated = false
  }

  if (status >= 400) {
    const errorMessage = 'An error occurred while processing the request.'
    throw new ApiError(status, errorMessage, data)
  }

  return response
}

// Centralized request method
const request = async <T>(options: HttpOptions): Promise<HttpResponse> => {
  const { method, url, params, data } = options

  const headers = await createHeaders(data instanceof FormData ? 'multipart/form-data' : 'application/json')
  const fullUrl = `${API_BASE_URL}${url}`

  try {
    const response = await CapacitorHttp.request({ method, url: fullUrl, params, data, headers })
    return await handleResponse(response)
  } catch (error) {
    throw error
  }
}

// API Methods
export const get = <T>(url: string, params?: HttpParams) => request<T>({ method: 'GET', url, params })
export const post = <T>(url: string, data?: any) => request<T>({ method: 'POST', url, data })
export const put = <T>(url: string, data: any) => request<T>({ method: 'PUT', url, data })
export const del = <T>(url: string) => request<T>({ method: 'DELETE', url })
export const postFormData = <T>(url: string, data: FormData) => request<T>({ method: 'POST', url, data })
