import type { Credentials } from '@/types/Credentials'
import * as httpClient from './capacitor-http-client'
import type { User } from '@/types/User'

const login = (payload: Credentials) => httpClient.post<User>('/auth/login', payload).then((response) => response.data)

const signup = (
  credentials: Pick<
    Credentials,
    'email' | 'password' | 'acknowledged_terms_and_conditions' | 'acknowledged_privacy_policy'
  >
) => httpClient.post<User>('/auth/signup', credentials).then((response) => response.data)

const logout = () => httpClient.post('/auth/logout')

const verifyPin = (pin: string) => httpClient.post('/auth/verify', { pin })

const resendPin = () => httpClient.get('/auth/verify/resend')

const authService = { login, signup, logout, verifyPin, resendPin }

export default authService
