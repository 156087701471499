<template>
  <transition name="fade">
    <div
      v-show="isActive || isActiveDelay"
      ref="velmld"
      :class="{ 'overflow-hidden': isActive || isActiveDelay }"
      :style="{ backgroundColor }"
      class="velmld-overlay">
      <div class="velmld-spinner">
        <slot name="default">
          <component
            :is="Spinner"
            :color="color"
            :size="`${size}px`"
            :duration="`${duration}s`" />
        </slot>
        <div
          v-if="text.length"
          :style="{ color, ...textStyle }">
          {{ text }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { onMounted, onBeforeUnmount, ref, watch } from 'vue'
  import Spinner from './Spinner.vue'
  const props = defineProps({
    active: Boolean,
    spinner: {
      type: String,
      default: 'spinner',
    },
    text: {
      type: String,
      default: '',
    },
    textStyle: {
      type: Object,
      default: function () {
        return {}
      },
    },
    color: {
      type: String,
      default: '#1e40af',
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: 'rgba(0, 0, 0, .9)',
    },
    size: {
      type: String,
      default: '60',
    },
    duration: {
      type: String,
      default: '0.6',
    },
    delay: {
      type: [String, Number],
      default: 0,
    },
  })

  const isActive = ref(props.active || false)
  const isActiveDelay = ref(false)
  const velmld = ref<HTMLInputElement | null>(null)

  onMounted(() => {
    velmld.value?.parentElement?.classList.add('velmld-parent')
    if (props.delay) {
      const delayMs = +props.delay * 1000
      delayActive(delayMs)
    }
  })

  onBeforeUnmount(() => {
    // Ensure to remove the overflow-hidden class when the component is unmounted
    document.body.classList.remove('overflow-hidden')
  })

  const delayActive = (ms: number) => {
    isActiveDelay.value = true
    setTimeout(() => {
      isActiveDelay.value = false
    }, ms)
  }

  watch(
    () => props.active,
    (value) => {
      isActive.value = value
      if (value) {
        velmld.value?.parentElement?.classList.add('velmld-parent')
        document.body.classList.add('overflow-hidden') // Add class to body to prevent scrolling
      } else {
        document.body.classList.remove('overflow-hidden') // Remove class from body to allow scrolling
      }
    }
  )
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .velmld-overlay {
    position: fixed;
    height: 100vh;
    z-index: 9999; /* Ensure it appears above other content */
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;
  }

  .velmld-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
  }
</style>
