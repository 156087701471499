import { useToastStore } from '@/stores/toast-store'
import { ApiError } from '@/types/ApiError'

export function handleError(error: unknown): void {
  const toast = useToastStore()

  let errorMessage: string

  if (error instanceof ApiError) {
    if (typeof error.data === 'string') {
      errorMessage = error.data
    } else if (Array.isArray(error.data?.errors)) {
      errorMessage = error.data.errors.join(', ')
    } else {
      errorMessage = error.message || 'An API error occurred'
    }
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    console.error('Unexpected error:', error)
    errorMessage = 'An unexpected error occurred'
  }

  toast.displayError(errorMessage)
}
